import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { HttpClient } from "../../api/httpClient";

const baseName = "wallet";

const initialState = {
  personal: {
    status: "idle",
    error: null,
    accts: [],
    defaultAcct: null,
    balance: {
      status: "idle",
      error: null,
      data: {
        availableBalance: 0,
        balance: 0,
        totalAmountExpenses: 0,
      },
    },
  },
  approver: {},
  financial: {
    status: "idle",
    error: null,
    accts: [],
    defaultAcct: null,
  },
};

export const getPersonalWallet = createAsyncThunk(
  `${baseName}/getPersonalWallet`,
  async () => {
    const res = await HttpClient.get({
      url: "/wallet",
      params: { role: "personal" },
      shouldCache: false,
    });
    return res?.data;
  }
);

export const getBalanceByAcct = createAsyncThunk(
  `${baseName}/getBalanceByAcct`,
  async ({ role, clearCache, signal, acctId, forceRecaulculate }) => {
    const res = await HttpClient.get({
      url: `/wallet/${acctId}/balance`,
      params: { role, forceRecaulculate },
      shouldCache: !clearCache,
      signal,
    });
    return res?.data;
  }
);

export const getFinancialAccounts = createAsyncThunk(
  `${baseName}/getFinancialAccounts`,
  async () => {
    const res = await HttpClient.get({
      url: `/financialAccounts`,
      params: { role: "financial", forceRecaulculate: 1 },
      shouldCache: false,
    });
    return res?.data;
  }
);

export const walletSlice = createSlice({
  name: baseName,
  initialState,
  reducers: {
    updatePersonalBalance(state, { payload }) {
      state.personal.balance = parseFloat(payload || 0);
    },
    togglePersonalDefaultAcct(state, { payload }) {
      if (!payload) return;
      state.personal.defaultAcct = payload;
    },
    toggleFinancialDefaultAcct(state, { payload }) {
      if (!payload) return;
      state.financial.defaultAcct = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //PESSOAL
      .addCase(getPersonalWallet.pending, (state, action) => {
        state.personal.status = "loading";
      })
      .addCase(getPersonalWallet.rejected, (state, action) => {
        state.personal.status = "failed";
      })
      .addCase(getPersonalWallet.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.personal.status = "succeeded";
        }
        state.personal.accts = action.payload || [];
        state.personal.defaultAcct = action.payload[0] || {};
      })
      //
      .addCase(getBalanceByAcct.pending, (state, action) => {
        const { role } = action.meta.arg;
        if (role === "personal") {
          state.personal.balance.status = "loading";
        }
      })
      .addCase(getBalanceByAcct.rejected, (state, action) => {
        const { role } = action.meta.arg;
        if (role === "personal") {
          state.personal.balance.status = "failed";
        }
      })
      .addCase(getBalanceByAcct.fulfilled, (state, action) => {
        const { role } = action.meta.arg;
        if (role === "personal") {
          state.personal.balance.status = "succeeded";
          state.personal.balance.data = action.payload || {};
        }
      })

      //FINANCIAL
      .addCase(getFinancialAccounts.pending, (state, action) => {
        state.financial.status = "loading";
      })
      .addCase(getFinancialAccounts.rejected, (state, action) => {
        state.financial.status = "failed";
      })
      .addCase(getFinancialAccounts.fulfilled, (state, action) => {
        state.financial.accts = action.payload || [];
        state.financial.defaultAcct = action.payload[0] || {};
      });
  },
});

export const {
  //actions
  updatePersonalBalance,
  togglePersonalDefaultAcct,
  toggleFinancialDefaultAcct,
} = walletSlice.actions;

export const selectPersonalAcct = createSelector(
  [(state) => state.wallet],
  (wallet) => {
    return (
      wallet.personal.defaultAcct || wallet.personal.accts?.data[0] || null
    );
  }
);

export const selectPersonalAcctId = createSelector(
  [selectPersonalAcct],
  (defaultAcct) => {
    return defaultAcct?._id;
  }
);
export const selectPersonalAllAccts = (state) =>
  state.wallet.personal.accts?.data;
export const selectPersonalBalance = (state) => state.wallet.personal.balance;

export const selectPersonalAcctCurrency = createSelector(
  [selectPersonalAcct],
  (defaultAcct) => {
    return defaultAcct?.currency;
  }
);

export default walletSlice.reducer;
