import { combineReducers } from "@reduxjs/toolkit";
import accountSlice from "./accountSlice";
import expensesSlice from "./expensesSlice";

//bases
import errorBaseSlice from "./base/errorBaseSlice";
import snackbarBaseSlice from "./base/snackbarBaseSlice";

//persist
import advancesSlice from "./advancesSlice";
import modalsSlice from "./base/modalsSlice";
import settingsSlice from "./base/settingsSlice";
import billingSlice from "./billingSlice";
import accountsPlanSlice from "./configs/accountsPlanSlice";
import auditSlice from "./configs/auditSlice";
import branchesSlice from "./configs/branchesSlice";
import categoriesSlice from "./configs/categoriesSlice";
import groupsSlice from "./configs/groupsSlice";
import integratorsSlice from "./configs/integratorsSlice";
import paymentsSlice from "./configs/paymentsSlice";
import projectsSlice from "./configs/projectsSlice";
import routePoliciesSlice from "./configs/routePoliciesSlice";
import rulesSlice from "./configs/rulesSlice";
import usersSlice from "./configs/usersSlice";
import vehiclesSlice from "./configs/vehiclesSlice";
import notificationsSlice from "./notificationsSlice";
import reportsSlice from "./reportsSlice";
import transactionsSlice from "./transactionsSlice";
import walletSlice from "./walletSlice";

// const persistConfig = {
//   account: {
//     key: "account",
//     storage,
//     blacklist: ["checkingAccess", "accountError", "syncing"],
//     version: 0,
//     migrate: createMigrate(account_migrations, {
//       debug: false,
//     }),
//   },
//   settings: {
//     key: "settings",
//     storage,
//     blacklist: ["currentPosition"],
//   },
// };

export const rootReducer = combineReducers({
  //ACCOUNT
  account: accountSlice,

  //MAIN
  expenses: expensesSlice,
  reports: reportsSlice,
  advances: advancesSlice,
  transactions: transactionsSlice,
  wallet: walletSlice,

  //LAYOUT
  errorBase: errorBaseSlice,
  snackbarBase: snackbarBaseSlice,
  modalsBase: modalsSlice,
  settings: settingsSlice,

  //SETTINGS V2
  users: usersSlice,
  branches: branchesSlice,
  groups: groupsSlice,
  categories: categoriesSlice,
  payments: paymentsSlice,
  vehicles: vehiclesSlice,
  rules: rulesSlice,
  audit: auditSlice,
  routePolicies: routePoliciesSlice,
  projects: projectsSlice,
  accountsPlan: accountsPlanSlice,
  integrators: integratorsSlice,

  //
  billing: billingSlice,
  notifications: notificationsSlice,
});
