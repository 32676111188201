import {
  Assignment,
  AssignmentOutlined,
  BarChart,
  BarChartOutlined,
  Business,
  BusinessOutlined,
  CreditCard,
  CreditCardOutlined,
  Menu,
  ReceiptLong,
  ReceiptLongOutlined,
  Wallet,
  WalletOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import pckg from '../../../package.json';
import useRouteContext from "../../hooks/useRouteContext";
import {
  selectIsAdmin,
  selectIsFinancial,
  selectIsInsights,
} from "../../store/features/accountSlice";
import { closeElement } from "../../store/features/base/modalsSlice";
import { toggleExpandedSidebarContent } from "../../store/features/base/settingsSlice";
import rolesSettings from "../../utils/rolesSettings";
import SidebarButtonItem from "./components/SidebarButtonItem";

export const isPathSelected = (link, pathname) => {
  return new RegExp(`${link?.split("?")[0]}`).test(pathname);
};

function Sidebar(props) {
  const dispatch = useDispatch();

  const { routeId, role } = useRouteContext();
  const { pathname, search } = useLocation();

  const getIsSelected = (path) => isPathSelected(path, pathname + search);
  const lastExpensesFilter = localStorage.getItem("expensesFilter");

  const isAdmin = useSelector(selectIsAdmin);
  const isIsights = useSelector(selectIsInsights);
  const isFinancial = useSelector(selectIsFinancial);

  return (
    <Box
      component={"div"}
      onClick={(e) => dispatch(closeElement("modalExpenseView"))}
      overflow={"hidden"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      zIndex={(z) => z.zIndex.appBar + 40}
      pb={2}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={60}
      >
        <Tooltip
          enterDelay={1000}
          placement="right-start"
          title="Menu principal"
        >
          <IconButton
            onClick={() => dispatch(toggleExpandedSidebarContent())}
            disableTouchRipple
            sx={{ p: 1.5 }}
          >
            <Menu sx={{ fontSize: "25px" }} />
          </IconButton>
        </Tooltip>
      </Box>
      <SidebarButtonItem
        Icon={ReceiptLongOutlined}
        path={`/expenses/${
          rolesSettings.expenses?.profiles[role]?.visible ? role : "personal"
        }${
          lastExpensesFilter
            ? `?expensesFilter=${lastExpensesFilter?.toString()}`
            : ""
        }`}
        title={"Despesas"}
        tour_id={"expenses"}
        ActiveIcon={ReceiptLong}
        isSelected={getIsSelected("expenses")}
      />
      {isFinancial && (
        <SidebarButtonItem
          Icon={CreditCardOutlined}
          path={`/transactions`}
          title={"Transações"}
          ActiveIcon={CreditCard}
          isSelected={getIsSelected("transactions")}
        />
      )}

      <SidebarButtonItem
        Icon={WalletOutlined}
        ActiveIcon={Wallet}
        path={`/wallet/${
          rolesSettings.wallet.profiles[role]?.visible ? role : "personal"
        }`}
        isSelected={getIsSelected("wallet")}
        title={"Carteira"}
        tooltip={"Carteira"}
      />

      <SidebarButtonItem
        Icon={AssignmentOutlined}
        ActiveIcon={Assignment}
        tour_id={"reports"}
        path={`/reports/${
          rolesSettings.reports?.profiles[role]?.visible ? role : "personal"
        }`}
        title={"Relatórios"}
        isSelected={getIsSelected("reports")}
      />
      {isIsights && (
        <SidebarButtonItem
          Icon={BarChartOutlined}
          ActiveIcon={BarChart}
          path={"/insights"}
          title={"Insights"}
          isSelected={getIsSelected("insights")}
        />
      )}
      {isAdmin && (
        <SidebarButtonItem
          Icon={BusinessOutlined}
          ActiveIcon={Business}
          path={"/admin"}
          title={"Admin"}
          tooltip={"Administrador"}
          isSelected={getIsSelected("admin")}
        />
      )}
      <Box flex={1} />
      <Typography
        fontWeight={"400"}
        textAlign={"center"}
        color="text.secondary"
        variant="caption"
        noWrap
      >
        Versão
      </Typography>
      <Typography
        mt={-0.5}
        fontWeight={"500"}
        textAlign={"center"}
        color="text.primary"
        variant="caption"
        noWrap
      >
        {pckg.version}
      </Typography>
    </Box>
  );
}

export default memo(Sidebar);
